import { useState } from "react";
import { supabase } from "utils/supabaseClient";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { basePath } from "utils/siteConfig";

export default function Auth({ children, ...props }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const router = useRouter();


  const handlePassword = async (email, password) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signIn(
        { email, password }
      );
      if (error) throw error;
      await router.push(router.query['referer'] ?? "/spotter");
      await router.push(router.query['referer'] ?? "/spotter");
    } catch (error) {
      if (error.status == 429) {
        toast({
          title:
            "Pour des raisons de sécurité vous ne pouvez effectuer cette action plusieurs fois en moins de 60 secondes",
          status: "error",
          position: "bottom-left",
        });
      } else if (error.status == 400) {
        toast({
          title: "Mauvais email / mot de passe",
          status: "error",
          position: "bottom-left",
        });
      } else {
        toast({
          title: "Une erreur est survenue",
          status: "error",
          position: "bottom-left",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const handleMagicLink = async (email) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signIn(
        { email },
        {
          redirectTo: `${basePath}${router.query["referer"] ?? ""}`,
        }
      );
      if (error) throw error;
      toast({
        title: "Consultez vos mails pour le lien de connexion",
        description: "Pensez à vérifier vos spams",
        status: "success",
        position: "bottom-left",
      });
    } catch (error) {
      if (error.status == 429) {
        toast({
          title:
            "Pour des raisons de sécurité vous ne pouvez effectuer cette action plusieurs fois en moins de 60 secondes",
          status: "error",
          position: "bottom-left",
        });
      } else {
        toast({
          title: "Une erreur est survenue",
          status: "error",
          position: "bottom-left",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box minW="300px">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (password != "") {
            handlePassword(email, password);
          } else {
            handleMagicLink(email);
          }
        }}
      >
        <Heading fontSize="2xl" mb={4} color={"secondary.main"}>
          Connexion
        </Heading>
        <FormControl>
          <FormLabel htmlFor="email">
            Votre email
          </FormLabel>
          <Input
            id="email"
            type="email"
            isRequired
            placeholder="email@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            bgColor={"gray.100"}
            autoFocus
            focusBorderColor="secondary.main"
          />
        </FormControl>
        <FormControl my={4}>
          <FormLabel htmlFor="password">
            Mot de passe
          </FormLabel>
          <Input
            id="password"
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            bgColor={"gray.100"}
            focusBorderColor="secondary.main"
          />
        </FormControl>
        <Button
          mt={2}
          type="submit"
          colorScheme={"brand"}
          className="button block"
          isLoading={loading}
          isDisabled={email == ""}
          width="full"
        >
          {password == "" ? "Envoyer le lien magique" : "Connexion"}
        </Button>
        {children}
      </form>
    </Box>
  );
}
