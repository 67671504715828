import { Flex, Stack, Box, Link } from "@chakra-ui/react";
import Login from "components/Login";
import NextImage from "next/image";
import NextLink from "next/link";

import login0 from "public/images/backgrounds/login0.jpg";
import login1 from "public/images/backgrounds/login1.jpg";
import login2 from "public/images/backgrounds/login2.jpg";
import login6 from "public/images/backgrounds/login6.jpg";
import login7 from "public/images/backgrounds/login7.jpg";
import login8 from "public/images/backgrounds/login8.jpg";
import login9 from "public/images/backgrounds/login9.jpg";
import login10 from "public/images/backgrounds/login10.jpg";
import login11 from "public/images/backgrounds/login11.jpg";
import login12 from "public/images/backgrounds/login12.jpg";
import login13 from "public/images/backgrounds/login13.jpg";
import login14 from "public/images/backgrounds/login14.jpg";
import login15 from "public/images/backgrounds/login15.jpg";
import login16 from "public/images/backgrounds/login16.jpg";
import login17 from "public/images/backgrounds/login17.jpg";
import login18 from "public/images/backgrounds/login18.jpg";
import login19 from "public/images/backgrounds/login19.jpg";
import login20 from "public/images/backgrounds/login20.jpg";
import login21 from "public/images/backgrounds/login21.jpg";
import login22 from "public/images/backgrounds/login22.jpg";
import login23 from "public/images/backgrounds/login23.jpg";
import login24 from "public/images/backgrounds/login24.jpg";
import login25 from "public/images/backgrounds/login25.jpg";

const images = [
  login0,
  login1,
  login2,
  login6,
  login7,
  login8,
  login9,
  login10,
  login11,
  login12,
  login13,
  login14,
  login15,
  login16,
  login17,
  login18,
  login19,
  login20,
  login21,
  login22,
  login23,
  login24,
  login25,
];

const photoId = Math.floor(Math.random() * images.length);

export default function LoginLayout({ children, ...props }) {
  return (
    <Stack height={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex
        p={2}
        flex={1}
        align={"center"}
        justify={"center"}
        flexDir={"column"}
      >
        <Box mb={4}>
          <NextLink href="/" passHref>
            <Link>
              <NextImage src={"/images/banner.png"} width={280} height={100} />
            </Link>
          </NextLink>
        </Box>
        {children}
      </Flex>
      <Flex flex={1} display={{ base: "none", md: "flex" }}>
        <NextImage
          alt={"Login Image"}
          objectFit="cover"
          src={images[photoId]}
          placeholder="blur"
        />
      </Flex>
    </Stack>
  );
}

export const getServerSideProps = () => {


  return { props: { photoId } };
};
